import React, { Component } from "react";
import { Row, Col, Button, Form, Modal, Spinner } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import * as EmailValidator from "email-validator";
import * as Request from "superagent";

import App from "../App";

export class AboutStargate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      setShow: false,
      subscribeStep: 1,
      subscribeFirstName: "",
      subscribeLastName: "",
      subscribeEmailAddress: "",
      isReadySubscribe: false,
      isReadySubscribeSubmit: false,
      checkedReCaptcha: false,
      isProcessing: false,
      errors: { firstName: "", lastName: "", emailAddress: "" },
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.checkSubscribeEmail = this.checkSubscribeEmail.bind(this);
    this.submitSubscribe = this.submitSubscribe.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openWebsite = this.openWebsite.bind(this);
    this.onReCaptchaChange = this.onReCaptchaChange.bind(this);
  }

  showHideModal() {
    this.setState({ show: !this.state.show, subscribeStep: 1 });
  }

  async checkSubscribeEmail() {
    if (this.state.isReadySubscribe) {
      await this.setState({ isReadySubscribe: false, isProcessing: true });
      Request.post("/api/formsubmission/newsletter")
        .send(
          JSON.stringify({
            email: this.state.subscribeEmailAddress,
            listtype: "NewsLetter",
          })
        )
        .set("SyncAuthorization", App.syncAuthorization)
        .set("Content-Type", "application/json")
        .end((err, res) => {
          this.setState({ isReadySubscribe: true, isProcessing: false });
          if (res.statusCode == 400) {
            this.setState({
              showHideToast: true,
              toastMessage: App.serverError,
            });
          } else if (res.statusCode == 200) {
            this.setState({
              show: true,
              subscribeStep: res.body.Success ? 1 : 2,
            });
            if (this.state.subscribeStep == 2) {
              this.setState({
                subscribeEmailAddress: "",
                isReadySubscribe: false,
              });
            }
          } else if (res.statusCode == 404) {
            this.setState({
              showHideToast: true,
              toastMessage: App.serverError,
            });
          }
        });
    }
  }

  async submitSubscribe() {
    const subscribeStep = this.state.subscribeStep;
    //this.setState({ show: false });
    if (this.state.isReadySubscribeSubmit) {
      await this.setState({
        isReadySubscribeSubmit: false,
        isProcessing: true,
      });
      Request.post("/api/formsubmission")
        .send(
          JSON.stringify({
            firstName: this.state.subscribeFirstName,
            lastName: this.state.subscribeLastName,
            email: this.state.subscribeEmailAddress,
            listtype: "NewsLetter",
          })
        )
        .set("SyncAuthorization", App.syncAuthorization)
        .set("Content-Type", "application/json")
        .end((err, res) => {
          this.setState({ isReadySubscribeSubmit: true, isProcessing: false });
          if (res.statusCode == 400) {
            this.setState({
              showHideToast: true,
              toastMessage: App.serverError,
            });
          } else if (res.statusCode == 200) {
            if (res.body.Success) {
              this.setState({
                subscribeStep: res.body.IsAlreadySubscribed ? 2 : 3,
                subscribeEmailAddress: "",
              });
            } else {
              this.setState({
                showHideToast: true,
                toastMessage: App.serverError,
              });
            }
          } else if (res.statusCode == 404) {
            this.setState({
              showHideToast: true,
              toastMessage: App.serverError,
            });
          }
        });
    }
  }

  async handleChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    let _value = "";
    switch (name) {
      case "SubscribeFirstName":
        _value = value.slice(0, 30);
        errors.firstName =
          _value.length != 0 ? "" : "Pleae enter your first name";
        if (errors.firstName.length == 0) {
          await this.setState({ subscribeFirstName: _value });
        } else {
          await this.setState({ subscribeFirstName: "" });
        }
        break;
      case "SubscribeLastName":
        _value = value.slice(0, 30);
        errors.lastName =
          _value.length != 0 ? "" : "Pleae enter your last name";
        if (errors.lastName.length == 0) {
          await this.setState({ subscribeLastName: _value });
        } else {
          await this.setState({ subscribeLastName: "" });
        }
        break;
      case "SubscribeEmailAddress":
        errors.emailAddress =
          value.length != 0 ? "" : "Pleae enter your email address";
        if (errors.emailAddress.length == 0) {
          await this.setState({ subscribeEmailAddress: value });
        } else {
          await this.setState({ subscribeEmailAddress: "" });
        }
        break;
    }

    await this.setState({ errors, [name]: value });
    await this.setState({
      isReadySubscribe: EmailValidator.validate(
        this.state.subscribeEmailAddress
      ),
    });
    await this.setIsReadySubscribeSubmit();
  }
  openWebsite() {
    window.open("https://www.prostate.org.au/about-the-stargate-project/", "_blank");
  }

  async onReCaptchaChange(value) {
    await this.setState({ checkedReCaptcha: value != null ? true : false });
    await this.setIsReadySubscribeSubmit();
  }

  // Check isReadySubscribeSubmit
  async setIsReadySubscribeSubmit() {
    if (
      this.state.subscribeFirstName != "" &&
      this.state.subscribeLastName != "" &&
      this.state.subscribeEmailAddres != "" &&
      this.state.checkedReCaptcha
    ) {
      await this.setState({ isReadySubscribeSubmit: true });
    } else {
      await this.setState({ isReadySubscribeSubmit: false });
    }
  }

  render() {
    const subscribeStep = this.state.subscribeStep;
    let modalTitle;
    let modalSubTitle;
    let modalBoby;

    if (subscribeStep == 1) {
      modalTitle = "Subscribe to our newsletter";
      modalSubTitle =
        "Please fill in the following details to subscribe to our newsletter.";
      modalBoby = (
        <div>
          <Row className="margin-top-20 row-input-height">
            <Col md={6}>
              <Form.Group controlId="formSubscribeFirstName">
                <Form.Control
                  required
                  type="text"
                  name="SubscribeFirstName"
                  placeholder="First Name*"
                  onChange={this.handleChange}
                />
                {this.state.errors.firstName.length > 0 && (
                  <div className="error-message">
                    {this.state.errors.firstName}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formSubscribeLastName">
                <Form.Control
                  required
                  type="text"
                  name="SubscribeLastName"
                  placeholder="Last Name*"
                  onChange={this.handleChange}
                />
                {this.state.errors.lastName.length > 0 && (
                  <div className="error-message">
                    {this.state.errors.lastName}
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="row-input-height">
            <Col>
              <Form.Group>
                <Form.Control
                  type="text"
                  disabled
                  placeholder={this.state.subscribeEmailAddress}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <ReCAPTCHA
                sitekey="6LdngbIUAAAAALVCkKCIskB3QcxzD8jfIjzaTjWD"
                onChange={this.onReCaptchaChange}
              />
            </Col>
          </Row>
        </div>
      );
    } else if (subscribeStep == 2) {
      modalTitle = "You are already subscribed";
      modalSubTitle =
        "The email you have entered is already subscribed to receive our newsletters.";
      modalBoby =
        "If you wish to add a different email address, please click on the subscribe button and enter your details again.";
    } else if (subscribeStep == 3) {
      modalTitle = "Thank you!";
      modalSubTitle =
        "Thank you for subscribing to our newsletter. You should receive a confirmation email soon.";
      modalBoby = "";
    }

    return (
      <div className="stargate-section">
        <div className="about-pcfa-content">
          <div className="container">
            <Row className="d-block d-xs-block d-sm-none d-md-none d-lg-none d-xl-none">
              <Col className="pr-0 img-about-stargate-mobile">
                <img src="/images/about-stargate.jpg"></img>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Row>
                  <Col className="pl-0 mt-3 section-title">
                    About the STARGATE Project
                  </Col>
                </Row>
                <Row>
                  <Col className="pl-0 mt-2">
                    <p>
                      The STARGATE project aims to improve prostate cancer
                      awareness by providing information on the burden of
                      disease at a regional level, nationwide.{" "}
                    </p>
                    <p>
                      You can search by postcode for factsheets of
                      specific Australian regions and share these with your
                      friends, family, and workmates to help improve community
                      understanding and save lives.{" "}
                    </p>
                    <p>
                      The data includes diagnoses, deaths, and prostate cancer
                      stage at diagnosis, unlocking information that has not
                      previously been shared in this way with the community.
                    </p>
                  </Col>
                </Row>
                <Row className="pl-0 margin-top-28">
                  <Col className="px-0">
                    <Button
                      id="on_click_learn_more_about_research"
                      className="btn-open-website"
                      onClick={this.openWebsite}
                    >
                      Click here to learn more about the research
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className="pr-0 img-about-stargate-desktop d-none d-xs-none d-sm-block d-md-block d-lg-block"
              >
                <img src="/images/about-stargate.jpg"></img>
              </Col>
            </Row>
          </div>
          <div>
            <Row className="subscribe-pcfa-news margin-top-50 d-none d-xs-none d-sm-block d-md-block d-lg-block">
              <Col className="text-center">
                <div className="reg-bold-55">Subscribe to PCFA News</div>
                <div>
                  Learn more about prostate cancer awareness and treatment via
                  our newsletter
                </div>
                <div className="prostate-cancer-support-container">
                  <Row className="margin-top-20 row-input-height">
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                      <Form.Group controlId="formSubscribeEmailAddress">
                        <Form.Control
                          required
                          type="email"
                          width="100%"
                          name="SubscribeEmailAddress"
                          value={this.state.subscribeEmailAddress}
                          placeholder="Email Address"
                          onChange={this.handleChange}
                        />
                        {this.state.errors.emailAddress.length > 0 && (
                          <div className="error-message">
                            {this.state.errors.emailAddress}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Button
                        id="on_subscribe_to_PCFA_news_now"
                        className="btn-prostate-cancer-support"
                        disabled={!this.state.isReadySubscribe}
                        onClick={this.checkSubscribeEmail}
                      >
                        Subscribe Now
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Form>
          <div className="subscribe-pcfa-news d-block d-xs-block d-sm-none d-md-none d-lg-none">
            <div className="reg-bold-24">Subscribe to PCFA News</div>
            <div>
              Learn more about prostate cancer awareness and treatment via our
              newsletter
            </div>
            <div className="prostate-cancer-support-container">
              <Row className="margin-top-20">
                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                  <Form.Group controlId="formSubscribeEmailAddress">
                    <Form.Control
                      required
                      type="email"
                      width="100%"
                      name="SubscribeEmailAddress"
                      value={this.state.subscribeEmailAddress}
                      placeholder="Email Address"
                      onChange={this.handleChange}
                    />
                    {this.state.errors.emailAddress.length > 0 && (
                      <div className="error-message">
                        {this.state.errors.emailAddress}
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Button
                    id="on_subscribe_to_PCFA_news_now"
                    className="btn-prostate-cancer-support"
                    disabled={!this.state.isReadySubscribe}
                    onClick={this.checkSubscribeEmail}
                  >
                    Subscribe Now
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
        <Modal
          show={this.state.show}
          onHide={this.showHideModal}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="popup-modal-title">
              {modalTitle}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="popup-modal-body">
            {modalSubTitle}
            <Form>
              {modalBoby}
              <Row className="margin-top-20">
                <Col md={6}>
                  {subscribeStep == 1 ? (
                    <Button
                      id="on_subscribe_to_PCFA_news"
                      variant="primary"
                      disabled={!this.state.isReadySubscribeSubmit}
                      onClick={this.submitSubscribe}
                    >
                      {this.state.isProcessing ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : null}
                      Submit
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={this.showHideModal}>
                      Close
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
