import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";

export class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="footer-content">
        <div className="d-none d-xs-none d-sm-block d-md-block d-lg-block d-xl-block">
          <div className="container">
            <Row>
              <Col>
                <img src="/images/footer-logo.png"></img>
              </Col>
              <Col className="text-center reg-regular-13-grey vertical-center">
                Copyright © 2020 Prostate Cancer Foundation of Australia.
              </Col>
              <Col className="text-right vertical-center">
                <a
                  id="on_click_social_network_facebook"
                  href="http://www.facebook.com/pcfa1"
                  target="_blank"
                  className="btn-social"
                >
                  <img src="/images/icon-facebook.png"></img>
                </a>
                <a
                  id="on_click_social_network_linkedin"
                  href="http://www.linkedin.com/company/prostate-cancer-foundation-of-australia"
                  target="_blank"
                  className="btn-social"
                >
                  <img src="/images/icon-linkedin.png"></img>
                </a>
                <a
                  id="on_clicking_social_network_twitter"
                  href="http://twitter.com/pcfa"
                  target="_blank"
                  className="btn-social"
                >
                  <img src="/images/icon-twitter.png"></img>
                </a>
                <a
                  id="on_clicking_social_network_youtube"
                  href="http://www.youtube.com/user/pcfaweb"
                  target="_blank"
                  className="btn-social"
                >
                  <img src="/images/icon-youtube.png"></img>
                </a>
              </Col>
            </Row>
          </div>
        </div>
        <div className="d-block d-xs-block d-sm-none d-md-none d-lg-none d-xl-none text-center">
          <div>
            <img src="/images/footer-logo.png"></img>
          </div>
          <div className="margin-top-20">
            <a
              id="on_click_social_network_facebook"
              href=" https://www.facebook.com"
              target="_blank"
              className="btn-social"
            >
              <img src="/images/icon-facebook.png"></img>
            </a>
            <a
              id="on_click_social_network_linkedin"
              href="https://www.linkedin.com"
              target="_blank"
              className="btn-social"
            >
              <img src="/images/icon-linkedin.png"></img>
            </a>
            <a
              id="on_clicking_social_network_twitter"
              href="https://www.twitter.com"
              target="_blank"
              className="btn-social"
            >
              <img src="/images/icon-twitter.png"></img>
            </a>
            <a
              id="on_clicking_social_network_youtube"
              href="https://www.youtube.com"
              target="_blank"
              className="btn-social"
            >
              <img src="/images/icon-youtube.png"></img>
            </a>
          </div>
          <div className="margin-top-20 reg-regular-12-grey">
            Copyright © 2020 Prostate Cancer Foundation of Australia.
          </div>
        </div>
      </div>
    );
  }
}
