import React, { Component } from "react";
import { Route } from "react-router";
import { Layout } from "./components/Layout";
import { Home } from "./components/Home";

import "./css/App.scss";

export default class App extends Component {
  static displayName = App.name;
  static syncAuthorization = "77e0D975$TbLb3dVbc6d2$ccda4G5b3bRda48880f";
  static filePath = "/uploads/";
  static serverError = "System error";

  render() {
    return <Route exact path="/" component={Home} />;
  }
}
