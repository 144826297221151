import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SearchSkeleton = () => {
    return (
        <div className="searched-result-container">
            <SkeletonTheme color="#77777780" highlightColor="#7777770D">
                <div className="text-left">
                    <Skeleton height={50} />
                </div>
            </SkeletonTheme>
            <SkeletonTheme color="#77777780" highlightColor="#7777770D">
                <div className="text-left reg-regular-default"> <Skeleton height={30} width={230} /> <Skeleton height={30} width={100} /></div>
            </SkeletonTheme>
        </div>
    );
};

export default SearchSkeleton;