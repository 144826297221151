import React, { Component } from "react";
import { Row, Col, Button, Form, Modal, Spinner } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import * as EmailValidator from "email-validator";
import * as Request from "superagent";

import App from "../App";

export class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      setShow: false,
      contactUsFormFirstName: "",
      contactUsFormLastName: "",
      contactUsFormEmailAddress: "",
      contactUsFormMessage: "",
      checkedReCaptcha: false,
      isReadySubmit: false,
      isProcessing: false,
      errors: { firstName: "", lastName: "", emailAddress: "", message: "" },
    };

    this.showHideModal = this.showHideModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onReCaptchaChange = this.onReCaptchaChange.bind(this);
    this.submitSubmit = this.submitSubmit.bind(this);
  }

  showHideModal() {
    this.setState((state) => ({
      show: !state.show,
    }));
  }

  async onReCaptchaChange(value) {
    await this.setState({ checkedReCaptcha: value != null ? true : false });
    await this.setIsReadySubmit();
  }

  async handleChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    let _value = "";

    switch (name) {
      case "ContactUsFirstName":
        _value = value.slice(0, 30);
        errors.firstName =
          _value.length != 0 ? "" : "Pleae enter your first name";
        if (errors.firstName.length == 0) {
          await this.setState({ contactUsFormFirstName: _value });
        } else {
          await this.setState({ contactUsFormFirstName: "" });
        }
        break;
      case "ContactUsLastName":
        _value = value.slice(0, 30);
        errors.lastName =
          _value.length != 0 ? "" : "Pleae enter your last name";
        if (errors.lastName.length == 0) {
          await this.setState({ contactUsFormLastName: _value });
        } else {
          await this.setState({ contactUsFormLastName: "" });
        }
        break;
      case "ContactUsEmailAddress":
        errors.emailAddress =
          value.length != 0 ? "" : "Pleae enter your email address";
        if (errors.emailAddress.length == 0) {
          await this.setState({ contactUsFormEmailAddress: value });
        } else {
          await this.setState({ contactUsFormEmailAddress: "" });
        }
        if (!EmailValidator.validate(value)) {
          errors.emailAddress = "Pleae check your email address";
        }
        break;
      case "ContactUsMessage":
        errors.message = value.length != 0 ? "" : "Pleae enter message";
        if (errors.message.length == 0) {
          await this.setState({ contactUsFormMessage: value });
        } else {
          await this.setState({ contactUsFormMessage: "" });
        }
        break;
    }
    await this.setState({ errors, [name]: value });
    await this.setIsReadySubmit();
  }

  async setIsReadySubmit() {
    if (
      this.state.contactUsFormFirstName != "" &&
      this.state.contactUsFormLastName != "" &&
      this.state.contactUsFormEmailAddress != "" &&
      this.state.contactUsFormMessage != "" &&
      EmailValidator.validate(this.state.contactUsFormEmailAddress) &&
      this.state.checkedReCaptcha
    ) {
      await this.setState({ isReadySubmit: true });
    } else {
      await this.setState({ isReadySubmit: false });
    }
  }

  async submitSubmit() {
    if (this.state.isReadySubmit) {
      await this.setState({ isReadySubmit: false, isProcessing: true });
      Request.post("/api/formsubmission")
        .send(
          JSON.stringify({
            firstName: this.state.contactUsFormFirstName,
            lastName: this.state.contactUsFormLastName,
            email: this.state.contactUsFormEmailAddress,
            message: this.state.contactUsFormMessage,
            listtype: "ContactUs",
          })
        )
        .set("SyncAuthorization", App.syncAuthorization)
        .set("Content-Type", "application/json")
        .end((err, res) => {
          this.setState({ isReadySubscribeSubmit: true, isProcessing: false });
          if (res.statusCode == 400) {
            this.setState({
              showHideToast: true,
              toastMessage: App.serverError,
            });
          } else if (res.statusCode == 200) {
            if (res.body.Success) {
              this.setState({
                show: true,
                contactUsFormFirstName: "",
                contactUsFormLastName: "",
                contactUsFormEmailAddress: "",
                contactUsFormMessage: "",
              });
            } else {
              this.setState({
                showHideToast: true,
                toastMessage: App.serverError,
              });
            }
          } else if (res.statusCode == 404) {
            this.setState({
              showHideToast: true,
              toastMessage: App.serverError,
            });
          }
        });
    }
  }

  render() {
    return (
      <div className="contact-us-content container">
        <Form>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-center">
              <div className="contact-us-sub-content">
                <Row>
                  <Col className="section-title text-left">Contact Us</Col>
                </Row>
                <hr />
                <Row>
                  <Col className="lbl-contact-us-subtitle text-left">
                    How can we help you?
                  </Col>
                </Row>
                <Row className="margin-top-20 row-input-height">
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Group controlId="formContactUsFirstName">
                      <Form.Control
                        required
                        type="text"
                        width="100%"
                        name="ContactUsFirstName"
                        placeholder="First Name*"
                        value={this.state.contactUsFormFirstName}
                        onChange={this.handleChange}
                      />
                      {this.state.errors.firstName.length > 0 && (
                        <div className="error-message">
                          {this.state.errors.firstName}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Group controlId="formContactUsLastName">
                      <Form.Control
                        required
                        type="text"
                        width="100%"
                        name="ContactUsLastName"
                        placeholder="Last Name*"
                        value={this.state.contactUsFormLastName}
                        onChange={this.handleChange}
                      />
                      {this.state.errors.lastName.length > 0 && (
                        <div className="error-message">
                          {this.state.errors.lastName}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="row-input-height">
                  <Col>
                    <Form.Group controlId="formContactUsEmailAddress">
                      <Form.Control
                        required
                        type="email"
                        width="100%"
                        name="ContactUsEmailAddress"
                        placeholder="Email address*"
                        value={this.state.contactUsFormEmailAddress}
                        onChange={this.handleChange}
                      />
                      {this.state.errors.emailAddress.length > 0 && (
                        <div className="error-message">
                          {this.state.errors.emailAddress}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="row-textarea-height">
                  <Col>
                    <Form.Group controlId="formContactUsEmail">
                      <Form.Control
                        as="textarea"
                        rows="8"
                        name="ContactUsMessage"
                        placeholder="Messages*"
                        value={this.state.contactUsFormMessage}
                        onChange={this.handleChange}
                      />
                      {this.state.errors.message.length > 0 && (
                        <div className="error-message">
                          {this.state.errors.message}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                    className="recapcha-container text-center"
                  >
                    <ReCAPTCHA
                      sitekey="6LdngbIUAAAAALVCkKCIskB3QcxzD8jfIjzaTjWD"
                      onChange={this.onReCaptchaChange}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Button
                      id="on_submit_contact_us"
                      className="btn-open-website"
                      disabled={!this.state.isReadySubmit}
                      onClick={this.submitSubmit}
                    >
                      {this.state.isProcessing ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : null}
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="contact-us-sub-content">
                <div className="contact-us-sub-content-hr">
                  <hr />
                </div>
                <Row>
                  <Col className="contact-us-sub-content-title-top text-left">
                    National Office Address
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="contact-us-sub-content-body">Level 8</div>
                    <div className="contact-us-sub-content-body">
                      1 Chandos St
                    </div>
                    <div className="contact-us-sub-content-body">
                      St Leonards NSW 2065
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="contact-us-sub-content-title text-left">
                    Mail Address
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="contact-us-sub-content-body">
                      PO Box 499
                    </div>
                    <div className="contact-us-sub-content-body">
                      St Leonards NSW 1590
                    </div>
                    <div className="contact-us-sub-content-body">Australia</div>
                  </Col>
                </Row>
                <Row>
                  <Col className="contact-us-sub-content-title text-left">
                    Contact Details
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="contact-us-sub-content-body">
                      Free call: 1800 22 00 99
                    </div>
                    <div className="contact-us-sub-content-body">
                      Telephone: 61 2 9438 7000
                    </div>
                    <div className="contact-us-sub-content-body">
                      Facsimile: 61 2 9438 7099
                    </div>
                    <div className="contact-us-sub-content-body">
                      E-mail: enquiries@pcfa.org.au
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
        <Modal
          show={this.state.show}
          onHide={this.showHideModal}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="popup-modal-title">
              Thank you for your message
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="popup-modal-body">
            We’ll get back to you as soon as we can.
            <Row className="margin-top-20">
              <Col md={6}>
                <Button variant="primary" onClick={this.showHideModal}>
                  Close
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
