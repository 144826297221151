import React, { Component } from "react";
import {
  Row,
  Col,
  Collapse,
  Form,
  Container,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import Scroll from "react-scroll";
import { Search, AboutStargate, AboutPCFA, ContactUs, Footer } from ".";

let scroller = Scroll.scroller;

export class Home extends Component {
  static displayName = Home.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      sidebarOpen: false,
      sidebarClassname: "sidebar",
      showHideSubTopMenu: false,
      showHideScrollToTop: false,
      windowHeight: 0,
      windowWidth: 0,
    };

    this.openWebsite = this.openWebsite.bind(this);
    window.addEventListener("resize", this.update);
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    this.update();
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.update);
    window.removeEventListener("scroll", this.handleScroll);
  }

  toggleNavbar() {
    if (this.state.windowWidth > 576 && this.state.windowWidth <= 1055) {
      this.setState({ showHideSubTopMenu: !this.state.showHideSubTopMenu });
    } else {
      this.setState({
        sidebarOpen: !this.state.sidebarOpen,
        sidebarClassname: !this.state.sidebarOpen ? "sidebar open" : "sidebar",
        showHideSubTopMenu: false,
      });
    }
  }

  openWebsite() {
      window.open("https://www.pcfa.org.au/stargate-donate?utm_source=Stargate%20Website&utm_medium=OnlineDonation&utm_campaign=StargateDonation", "_blank");
  }

  update = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      showHideSubTopMenu:
        this.state.showHideSubTopMenu && this.state.windowWidth <= 576
          ? false
          : this.state.showHideSubTopMenu,
    });
    if (this.state.showHideSubTopMenu && this.state.windowWidth >= 1056) {
      this.setState({
        showHideSubTopMenu: false,
      });
    }
  };

  handleScroll = () => {
    this.setState({ showHideScrollToTop: window.scrollY >= 100 });
  };

  render() {
    return (
      <div>
        <div className="d-block d-xs-block d-sm-none d-md-none d-lg-none d-xl-none">
          <div className={this.state.sidebarClassname}>
            <div onClick={this.toggleNavbar} className="cursor-pointer">
              <FontAwesomeIcon icon={faTimes} size="2x" />
            </div>
            <ul className="navbar-nav flex-grow margin-top-50">
              <NavItem>
                <NavLink
                  href="#aboutStargateContent"
                  onClick={this.toggleNavbar}
                >
                  About the STARGATE Project
                </NavLink>
                <hr />
              </NavItem>
              <NavItem>
                <NavLink href="#aboutPcfaContent" onClick={this.toggleNavbar}>
                  About PCFA
                </NavLink>
                <hr />
              </NavItem>
              <NavItem>
                <NavLink href="#contactUsContent" onClick={this.toggleNavbar}>
                  Contact Us
                </NavLink>
              </NavItem>
            </ul>
          </div>
        </div>
        <a id="topContent" name="topContent"></a>
        <div className="top-content">
          <div className="container">
            <Navbar
              className="navbar-expand-sm navbar-toggleable-sm"
              light="true"
            >
              <Container>
                <div className="top-nav-menu-logo">
                  <NavbarBrand to="/">
                    <img src="/images/logo.png"></img>
                  </NavbarBrand>
                </div>
                <div className="top-nav-bugger-menu div-width-100">
                  <span onClick={this.toggleNavbar}>
                    <FontAwesomeIcon icon={faBars} className="fa-icon-30" />
                  </span>
                  <span>
                    <Button
                      id="on_click_donate_button"
                      variant="primary"
                      className="btn-donate float-right"
                      onClick={this.openWebsite}
                    >
                      Donate
                    </Button>
                  </span>
                </div>
                <div className="top-nav-menu">
                  <ul className="navbar-nav flex-grow">
                    <NavItem>
                      <NavLink
                        onClick={() =>
                          scroller.scrollTo("aboutStargateContent", {
                            smooth: true,
                            offset: 0,
                            duration: 500,
                          })
                        }
                      >
                        About the STARGATE Project
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        onClick={() =>
                          scroller.scrollTo("aboutPcfaContent", {
                            smooth: true,
                            offset: -50,
                            duration: 500,
                          })
                        }
                      >
                        About PCFA
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        onClick={() =>
                          scroller.scrollTo("contactUsContent", {
                            smooth: true,
                            offset: 0,
                            duration: 500,
                          })
                        }
                      >
                        Contact Us
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <Button
                        id="on_click_donate_button"
                        variant="primary"
                        className="btn-donate float-right"
                        onClick={this.openWebsite}
                      >
                        Donate
                      </Button>
                    </NavItem>
                  </ul>
                </div>
              </Container>
            </Navbar>
            <div
              className={`${
                this.state.showHideSubTopMenu
                  ? "top-sub-menu-slidedown"
                  : "top-sub-menu-slideup"
              }`}
            >
              <ul className="navbar-nav">
                <NavItem>
                  <NavLink
                    onClick={() =>
                      scroller.scrollTo("aboutStargateContent", {
                        smooth: true,
                        offset: 0,
                        duration: 500,
                      })
                    }
                  >
                    About the STARGATE Project
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() =>
                      scroller.scrollTo("aboutPcfaContent", {
                        smooth: true,
                        offset: -50,
                        duration: 500,
                      })
                    }
                  >
                    About PCFA
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() =>
                      scroller.scrollTo("contactUsContent", {
                        smooth: true,
                        offset: 0,
                        duration: 500,
                      })
                    }
                  >
                    Contact Us
                  </NavLink>
                </NavItem>
              </ul>
            </div>
            <Search />
          </div>
        </div>
        <div id="aboutStargateContent" name="aboutStargateContent"></div>
        <AboutStargate />
        <div id="aboutPcfaContent" name="aboutPcfaContent"></div>
        <AboutPCFA />
        <div id="contactUsContent" name="contactUsContent"></div>
        <ContactUs />
        <div name="donateContent"></div>
        <Footer />
        {this.state.showHideScrollToTop ? (
          <div className="scroll-to-top">
            <Button
              onClick={() =>
                scroller.scrollTo("topContent", {
                  smooth: true,
                  offset: 0,
                  duration: 500,
                })
              }
            >
              <FontAwesomeIcon
                icon={faArrowUp}
                className="scroll-to-top-fa-icon"
              />
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}
