import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Spinner,
  Toast,
  NavLink,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faMapMarkerAlt,
  faDownload,
  faEnvelope,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import * as Request from "superagent";
import * as EmailValidator from "email-validator";
import Scroll from "react-scroll";
import ReactAutocomplete from "react-autocomplete";
import TagManager from 'react-gtm-module'

import App from "../App";
import { SlaS4 } from "../models";
import SearchSkeleton from "./SearchSkeleton";

let scroller = Scroll.scroller;

export class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      setShow: false,
      showSlaS4: false,
      searchedPostcode: "",
      autoCompleteSA2List: [],
      searchedSlaS4: SlaS4,
      sendEmailFormFirstName: "",
      sendEmailFormLastName: "",
      sendEmailFormEmailAddress: "",
      isReadySendEmail: false,
        isSearching: false,
        isSearchingMinSec: false,
      isProcessing: false,
      showHideToast: false,
      toastMessage: "",
      errors: { firstName: "", lastName: "", emailAddress: "" },
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDownloadClicked = this.onDownloadClicked.bind(this);
    this.OnSendEmailClicked = this.OnSendEmailClicked.bind(this);
      this.SetShowHideToast = this.SetShowHideToast.bind(this);
      
  }

  async componentDidMount() {
    var slas4 = localStorage.getItem("slas4");
    if (slas4 != undefined) {
      slas4 = JSON.parse(slas4);
      await this.setState({
        showSlaS4: true,
        searchedSlaS4: slas4,
      });
      }
  }

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  showHideModal() {
    this.setState((state) => ({
      show: !state.show,
    }));
  }

  SetShowHideToast(showHideToast) {
    this.setState({ showHideToast: showHideToast });
  }

    replaceWrongAddresses(address) {
        if (address.includes("Himas"))
            return address.replace("Himas", "HMAS");
        else
            return address;
    }

  async handleChange(event) {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    let _value = "";

    switch (name) {
      case "FirstName":
        _value = value.slice(0, 30);
        errors.firstName =
          _value.length != 0 ? "" : "Pleae enter your first name";
        if (errors.firstName.length == 0) {
          await this.setState({ sendEmailFormFirstName: _value });
        } else {
          await this.setState({ sendEmailFormFirstName: "" });
        }
        break;
      case "LastName":
        _value = value.slice(0, 30);
        errors.lastName =
          _value.length != 0 ? "" : "Pleae enter your last name";
        if (errors.lastName.length == 0) {
          await this.setState({ sendEmailFormLastName: _value });
        } else {
          await this.setState({ sendEmailFormLastName: "" });
        }
        break;
      case "EmailAddress":
        errors.emailAddress =
          value.length != 0 ? "" : "Pleae enter your email address";
        if (errors.emailAddress.length == 0) {
          await this.setState({ sendEmailFormEmailAddress: value });
        } else {
          await this.setState({ sendEmailFormEmailAddress: "" });
        }
        break;
    }
    this.setState({ errors, [name]: value });
    this.checkSendEmail();
  }

  // Download Pdf
  onDownloadClicked() {
    window.open(App.filePath + this.state.searchedSlaS4.factsheet, "_blank");
  }

  checkSendEmail() {
    let _isReadySendEmail =
      this.state.sendEmailFormFirstName.length > 0 &&
      this.state.sendEmailFormLastName.length > 0 &&
      EmailValidator.validate(this.state.sendEmailFormEmailAddress) &&
      this.state.showSlaS4 &&
      this.state.searchedSlaS4.factsheet != null;
    this.setState({ isReadySendEmail: _isReadySendEmail });
  }

  async OnSendEmailClicked() {
    if (this.state.isReadySendEmail) {
      await this.setState({ isReadySendEmail: false, isProcessing: true });
      Request.post("/api/sla/sendemail")
        .send(
          JSON.stringify({
            firstName: this.state.sendEmailFormFirstName,
            lastName: this.state.sendEmailFormLastName,
            emailAddress: this.state.sendEmailFormEmailAddress,
            factsheetid: this.state.searchedSlaS4.id,
            factsheetname: this.state.searchedSlaS4.factsheet,
          })
        )
        .set("SyncAuthorization", App.syncAuthorization)
        .set("Content-Type", "application/json")
        .end((err, res) => {
          this.setState({ isReadySendEmail: true, isProcessing: false });
          if (res.statusCode == 400) {
            this.setState({
              showHideToast: true,
              toastMessage: App.serverError,
            });
          } else if (res.statusCode == 200) {
            //Clear sendemail data
            this.setState({
              sendEmailFormFirstName: "",
              sendEmailFormLastName: "",
              sendEmailFormEmailAddress: "",
              isReadySendEmail: false,
            });
            this.showHideModal();
          } else if (res.statusCode == 404) {
            this.setState({
              showHideToast: true,
              toastMessage: App.serverError,
            });
          }
        });
    }
    }
    onChangeAutocompleteValue = async (value) => {
        if (value.length > 4) {
            return;
            
        } else {
            this.setState({ searchedPostcode: value, autoCompleteSA2List: [] });
        }

        if (value.length == 4) {
            Request.post("/api/sla/slas2list")
                .send(JSON.stringify({ Postcode: value }))
                .set("SyncAuthorization", App.syncAuthorization)
                .set("Content-Type", "application/json")
                .end((err, res) => {
                    if (res.statusCode == 400) {
                        this.setState({
                            showHideToast: true,
                            toastMessage: App.serverError,
                        });
                    } else if (res.statusCode == 200) {
                        if (res.body.length == 0) {
                            //show "No results found"
                            this.setState({ autoCompleteSA2List: [{ sa2code: 999999999, postcode: '9999', sa2name: 'No match found'}] })
                        } else {
                            this.setState({ autoCompleteSA2List: res.body })
                        }
                    } else if (res.statusCode == 404) {
                        this.setState({
                            showHideToast: true,
                            toastMessage: App.serverError,
                        });
                    }
                });
        }
    }
    // Search SLAS4
    onAutoCompleteSelected(slaS2) {
        this.setState({ searchedPostcode: '', autoCompleteSA2List: [] });

        if (slaS2.postcode == '9999') //if no match found, just quit
            return;

        if (slaS2 != undefined) {
            let sa4code =
                slaS2.sa2code == 0 || slaS2.sa2code < 100
                    ? 0
                    : slaS2.sa2code.toString().slice(0, 3);

            this.setState({
                showSlaS4: false,
                isSearching: true,
                isSearchingMinSec: true
            });
            this.sleep(1000).then(() => {
                this.setState({
                    isSearching: false,
                    isSearchingMinSec: false
                });
            });

            if (sa4code != undefined) {
                Request.post("/api/sla/slas4")
                    .send(JSON.stringify({ Sa4code: new Number(sa4code) }))
                    .set("SyncAuthorization", App.syncAuthorization)
                    .set("Content-Type", "application/json")
                    .end((err, res) => {
                        if (res.statusCode == 400) {
                            this.setState({
                                showHideToast: true,
                                isSearching: false,
                                toastMessage: App.serverError,
                            });
                        } else if (res.statusCode == 200) {
                            var slas4 = new SlaS4();
                            slas4.id = res.body.id;
                            slas4.sa4code = res.body.sa4code;
                            slas4.sa4name = res.body.sa4name;
                            slas4.statename = res.body.statename;
                            slas4.factsheet = res.body.factsheet;
                            slas4.postcode = slaS2.postcode;

                            // Save to local storage
                            localStorage.setItem("slas4", JSON.stringify(slas4));
                            // Clear email data
                            this.setState({
                                showSlaS4: true,
                                isSearching: false,
                                searchedSlaS4: slas4,
                                sendEmailFormFirstName: "",
                                sendEmailFormLastName: "",
                                sendEmailFormEmailAddress: "",
                            });
                            this.checkSendEmail();

                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'on_factsheet_search',
                                    postcode: slaS2.postcode
                                }
                            });
                        } else if (res.statusCode == 404) {
                            this.setState({
                                showHideToast: true,
                                isSearching: false,
                                toastMessage: App.serverError,
                            });
                        }
                    });
            }
        }
    }
    onInputFocus = (event) => {
        event.target.setAttribute('autoComplete', 'new-password');
    }
  render() {
    return (
      <div>
        <Form>
          <div className="search-container-top">
            <div className="d-none d-xs-none d-sm-block d-md-block d-lg-block d-xl-block text-center">
              <img src="/images/stargate-logo.png"></img>
            </div>
            <div className="d-block d-xs-block d-sm-none d-md-none d-lg-none d-xl-none text-center">
              <img src="/images/stargate-logo-mobile.png" width="320px"></img>
            </div>
          </div>
          <div className="margin-top-28 text-center">
            [proStaTe cAncer ReGional fAcT shEets]
          </div>
          <div className="margin-top-30 text-center">
            <div className="search-container">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text search-box-bgcolor search-box-border">
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                </div>
                            <ReactAutocomplete
                                items={this.state.autoCompleteSA2List}
                                getItemValue={item => item.postcode}
                                renderItem={(item, highlighted) =>
                                    <div
                                        key={item.sa2code}
                                        style={{
                                            backgroundColor: highlighted ? '#fff' : '#FFF',
                                            paddingLeft: 15, paddingRight: 15,
                                            paddingTop: 5, paddingBottom: 5,
                                            fontSize: 16, textAlign: "left",
                                            color: '#000'
                                        }}
                                    >
                                        {item.sa2name}
                                    </div>
                                }
                                inputProps={{ placeholder: 'Search by postcode', autoComplete: 'new-password', onFocus: this.onInputFocus, type: 'search', class: 'searchInput' }}
                                value={this.state.searchedPostcode}
                                onChange={e => this.onChangeAutocompleteValue(e.target.value)}
                                onSelect={(value, item) => this.onAutoCompleteSelected(item)}
                                wrapperStyle={{width:'230px'}}
                />
              </div>
            </div>
          </div>
          <div className="margin-top-50 text-center">
            <div className="searched-result-bg-container">
              {!this.state.showSlaS4 && !(this.state.isSearching || this.state.isSearchingMinSec) ? (
                <div className="searched-result-container">
                  Search by postcode in the search box above to download fact
                  sheets
                </div>
              ) : null}
              {(this.state.isSearching || this.state.isSearchingMinSec) ? (
                <SearchSkeleton />
              ) : this.state.showSlaS4 ? (
                <div className="searched-result-container">
                  <div className="text-left">
                    <span>
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        className="search-fa-icon"
                      />
                    </span>
                    <span className="searched-area">
                      {this.state.searchedSlaS4.sa4name}
                    </span>
                  </div>
                  <div className="text-left reg-regular-default">
                    {this.state.searchedSlaS4.statename},{" "}
                    {this.state.searchedSlaS4.postcode}
                  </div>
                </div>
              ) : null}

              <Row className="text-left margin-top-30">
                <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                  <span>
                    <FontAwesomeIcon icon={faDownload} className="fa-icon-20" />
                  </span>
                  <span className="reg-regular-default">
                    Download Fact Sheet
                  </span>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={7}
                  lg={7}
                  xl={7}
                  className="d-none d-xs-none d-sm-block d-md-block d-lg-block d-xl-block"
                >
                  <span>
                    <FontAwesomeIcon icon={faEnvelope} className="fa-icon-20" />
                  </span>
                  <span className="reg-regular-default">
                    Receive a copy by email
                  </span>
                </Col>
              </Row>
              <hr />
              <Row className="text-left">
                <Col md={5}>
                  <Row>
                    <Col md={8}>
                      <Button
                        id="on_click_factsheet_download"
                        disabled={this.state.searchedSlaS4.factsheet == null || this.state.isSearching || this.state.isSearchingMinSec}
                        onClick={() => this.onDownloadClicked()}
                      >
                        Download as PDF
                      </Button>
                    </Col>
                    <Col
                      md={4}
                      className="d-none d-xs-none d-sm-block d-md-block d-lg-block d-xl-block reg-regular-default text-right vertical-center"
                    >
                      OR
                    </Col>
                    <Col
                      md={4}
                      className="d-block d-xs-block d-sm-none d-md-none d-lg-none reg-regular-default text-center vertical-center"
                    >
                      <div className="lbl-or">OR</div>
                    </Col>
                  </Row>
                </Col>
                <Col md={7}>
                  <Row className="d-block d-xs-block d-sm-none d-md-none d-lg-none">
                    <Col>
                      <span>
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="fa-icon-20"
                        />
                      </span>
                      <span className="reg-regular-default">
                        Receive a copy by Email
                      </span>
                    </Col>
                  </Row>
                  <hr className="d-block d-xs-block d-sm-none d-md-none d-lg-none" />
                  <Row className="row-input-height">
                    <Col md={6}>
                      <Form.Group controlId="FirstName">
                        <Form.Control
                          type="text"
                          name="FirstName"
                          value={this.state.sendEmailFormFirstName}
                          placeholder="First Name*"
                          disabled={this.state.searchedSlaS4.factsheet == null}
                          onChange={this.handleChange}
                        />
                        {this.state.errors.firstName.length > 0 && (
                          <div className="error-message">
                            {this.state.errors.firstName}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formLastName">
                        <Form.Control
                          type="text"
                          name="LastName"
                          placeholder="Last Name*"
                          value={this.state.sendEmailFormLastName}
                          disabled={this.state.searchedSlaS4.factsheet == null}
                          onChange={this.handleChange}
                        />
                        {this.state.errors.lastName.length > 0 && (
                          <div className="error-message">
                            {this.state.errors.lastName}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="row-input-height">
                    <Col>
                      <Form.Group controlId="formEmailAddress">
                        <Form.Control
                          type="email"
                          name="EmailAddress"
                          placeholder="Email Address*"
                          value={this.state.sendEmailFormEmailAddress}
                          disabled={this.state.searchedSlaS4.factsheet == null}
                          onChange={this.handleChange}
                        />
                        {this.state.errors.emailAddress.length > 0 && (
                          <div className="error-message">
                            {this.state.errors.emailAddress}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Button
                        id="on_click_send_factsheet_via_email"
                        onClick={this.OnSendEmailClicked}
                        disabled={!this.state.isReadySendEmail}
                      >
                        {this.state.isProcessing ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : null}
                        Send to Email
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          <div className="margin-top-45 cursor-pointer d-none d-xs-none d-sm-block d-md-block d-lg-block d-xl-block">
            <NavLink
              onClick={() =>
                scroller.scrollTo("aboutStargateContent", {
                  smooth: true,
                  offset: 0,
                  duration: 500,
                })
              }
            >
              <div className="text-center reg-regular-default">SCROLL</div>
              <div className="text-center">
                <span>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="fa-icon-30"
                  />
                </span>
              </div>
            </NavLink>
          </div>
        </Form>
        <div aria-live="polite" aria-atomic="true">
          <Toast
            delay={3000}
            onClose={() => this.SetShowHideToast(false)}
            show={this.state.showHideToast}
            autohide
            className="toast-custom"
            style={{
              position: "fixed",
              bottom: 0,
              right: 0,
            }}
          >
            <Toast.Header closeButton={false}>
              <strong className="mr-auto">Message</strong>
            </Toast.Header>
            <Toast.Body className="toast-body-custom">
              {this.state.toastMessage}
            </Toast.Body>
          </Toast>
        </div>
        <Modal
          show={this.state.show}
          onHide={this.showHideModal}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="popup-modal-title">
              Fact Sheet Sent
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="popup-modal-body">
            <div>The PDF has been sent.</div>
            <div>Please share stargate.org.au with others.</div>
            <Row className="margin-top-20">
              <Col md={6}>
                <Button variant="primary" onClick={this.showHideModal}>
                  Close
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
