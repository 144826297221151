import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";

export class AboutPCFA extends Component {
  constructor(props) {
    super(props);
    this.openWebsite = this.openWebsite.bind(this);
  }

  openWebsite() {
    window.open("https://pcfa.org.au/", "_blank");
  }

  render() {
    return (
      <div className="about-pcfa-content">
        <div className="container">
          <Row className="d-block d-xs-block d-sm-none d-md-none d-lg-none d-xl-none">
            <Col className="px-0 img-about-pcfa-mobile">
              <img src="/images/about-pcfa.jpg" width="100%"></img>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Row>
                <Col className="pl-0 mt-3 section-title">About PCFA</Col>
              </Row>
              <Row>
                <Col className="pl-0">
                  <p>
                    We are Australia’s leading community-based organisation for
                    prostate cancer research, awareness, and support. As the
                    nation’s major charity fund for Australian-based prostate
                    cancer research, we exist to protect the health of existing
                    and future generations of men in Australia and to improve
                    quality of life for Australian men and families impacted by
                    the disease.
                  </p>
                  <p>
                    Our vision is a future where no man dies of prostate cancer
                    and Australian men and their families get the support they
                    need.
                  </p>
                  <p>Our focus:</p>
                  <ol>
                    <li>
                      To be Australia’s leading charity fund for
                      Australian-based prostate cancer research.
                    </li>
                    <li>
                      To protect the health of existing and future generations
                      of men in Australia.
                    </li>
                    <li>
                      To improve quality of life for Australian men diagnosed
                      with prostate cancer.
                    </li>
                  </ol>
                </Col>
              </Row>
              <Row className="pl-0 margin-top-28">
                <Col className="px-0">
                  <Button
                    id="on_click_go_to_our_website"
                    className="btn-open-website"
                    onClick={this.openWebsite}
                  >
                    Click here to go to our website
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="pr-0 img-about-pcfa-desktop d-none d-xs-none d-sm-block d-md-block d-lg-block"
            >
              <img
                src="/images/about-pcfa.jpg"
                width="100%"
                height="100%"
              ></img>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
